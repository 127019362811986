import { graphql } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

import { Footer } from '../components/Footer'
import { Nav } from '../components/Nav'
import { i18nEn } from '../language/en'
import DefaultLayout from '../layouts'

const Content = styled.article`
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
  margin-left: auto;
  margin-right: auto;

  --font-serif: Georgia, Times, serif;
  --font-mono: Menlo, Courier, monospace;

  h1,
  h2,
  h3 {
    font-weight: 700;
    line-height: 1.3em;
  }

  h1 {
    font-size: 42px;
    margin-top: 1.2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 30px;
    margin-top: 2.2em;
    margin-bottom: 0.3em;
  }

  h3 {
    font-size: 24px;
    margin-top: 1.6em;
    margin-bottom: 0.3em;
  }

  p,
  li {
    font-size: 21px;
    line-height: 1.58;
    font-family: 'Work Sans', 'Avenir Next';
  }

  p {
    margin-bottom: 1.75rem;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 50px;
    margin-bottom: 1.75rem;
  }

  li {
    margin-bottom: 1.25rem;
  }

  a {
    color: var(--blue);
    text-decoration: none;
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: 600;
  }

  blockquote {
    position: relative;
  }

  blockquote:after {
    position: relative;
  }

  blockquote {
    position: relative;
    padding-left: 1em;
    border-left: 0.2em solid var(--gray-blue);
  }
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const {
    frontmatter: { title, description, path },
    html,
  } = markdownRemark

  return (
    <DefaultLayout language="en" title={title} description={description} path={path} article>
      <Nav
        i18n={i18nEn}
        languagePrefix="/"
        otherLanguageUrl={'/'}
        showClientLink
        showCandidateLink
      />

      <Content>
        {/* <h1>{frontmatter.title}</h1> */}
        <section dangerouslySetInnerHTML={{ __html: html }} />
      </Content>

      <Footer i18n={i18nEn} />
    </DefaultLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        path
      }
    }
  }
`
